import scrollIntoView from 'scroll-into-view'

export default class Accordion {
  constructor($el) {
    this.$el = $el
    this.activeClass = 'is-accordion-open'
    this.animationTime = 200

    if (!$(this.$el).hasClass(this.activeClass)) {
      $(this.$el)
        .next()
        .css('display', 'none')
    }
    this.accordionInit(this.$el)
  }
  accordionClick(element) {
    $(element)
      .off('click')
      .on('click', e => {
        e.preventDefault()

        if ($(e.currentTarget).hasClass(this.activeClass)) {
          $(e.currentTarget).removeClass(this.activeClass)
          $(e.currentTarget)
            .next()
            .slideUp(this.animationTime)
        } else {
          // should siblings close?
          if ($(element).attr('data-close-others')) {
            this.accordionCloseOthers($(element).data('close-others'), element)
          }
          // add active class and show accordion
          $(e.currentTarget).addClass(this.activeClass)
          $(e.currentTarget)
            .next()
            .slideDown(this.animationTime)

          // scroll into view
          if ($(element).attr('data-scroll-into-view')) {
            scrollIntoView(e.currentTarget, {
              align: {
                top: 0,
                topOffset: $('.c-site-head').outerHeight() + 30,
              },
            })
          }
        }
      })
  }
  accordionDestroy(element) {
    $(element)
      .off('click')
      .on('click', e => {
        e.preventDefault()
      })
  }
  accordionCloseOthers(selector, element) {
    const elementsToClose = $(element)
      .parent()
      .parent()
      .find(selector)

    elementsToClose.removeClass(this.activeClass)
    elementsToClose.next().slideUp(this.animationTime)
  }
  accordionInit(element) {
    this.accordionClick(element)
  }
}
