import 'slick-carousel'

export default class Carousel {
  constructor($el) {
    this.$el = $($el)

    let defaultOptions = {
      arrows: false,
      dots: true,
    }

    this.options = defaultOptions

    if (!this.$el.attr('data-no-auto-init')) this.up()
  }

  up() {
    this.$el.slick(this.options)
  }

  down() {
    this.$el.slick('unslick')
  }
}
