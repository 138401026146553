// import external dependencies
import 'jquery'

// import local dependencies
import './modules'

import svg4everybody from 'svg4everybody'
svg4everybody()

import Sticky from 'sticky-js'
new Sticky('.c-sidebar')

import 'lity'
