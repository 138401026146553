import Carousel from './carousel'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

export default class Gallery {
  constructor($el) {
    this.$el = $($el)
    this.$link = this.$el.find('.js-gallery-link')
    this.$modal = this.$el.find('.js-gallery-modal')
    this.$modalCarousel = this.$modal.find('.js-gallery-modal__carousel')
    this.$modalClose = this.$modal.find('.js-gallery-modal__close')
    this.activeClass = 'is-modal-open'
    this.scrollArea = '.js-gallery-modal'

    this.$link.each((index, element) => {
      $(element).on('click', e => {
        e.preventDefault()
        this.openModal()

        this.carousel = new Carousel(this.$modalCarousel)
        this.carousel.options.initialSlide = index
        this.carousel.up()
      })
    })

    this.$modalClose.on('click', e => {
      e.preventDefault()
      this.closeModal()
    })

    $(document).on('keyup', e => {
      if (e.keyCode == 27) {
        this.closeModal()
      }
    })

    $(document).on('click', e => {
      const $target = $(e.target)
      if (
        !$target.closest(this.$modalCarousel).length &&
        !$target.closest(this.$modalClose).length &&
        !$target.closest(this.$link).length
      ) {
        this.closeModal()
      }
    })
  }
  openModal() {
    this.$modal.addClass(this.activeClass)
    setTimeout(() => {
      this.$modal.find('.slick-dots .slick-active > button').focus()
    }, 500)
    disableBodyScroll(this.scrollArea)
  }

  closeModal() {
    this.$modal.on('transitionend webkitTransitionEnd', e => {
      if (e.originalEvent.propertyName === 'opacity') {
        this.carousel.down()
        this.$modal.off('transitionend webkitTransitionEnd')
      }
    })

    this.$modal.removeClass(this.activeClass)
    enableBodyScroll(this.scrollArea)
  }
}
